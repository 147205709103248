<template>
    <div class="left-container">
        <div class="left-title">直播脚本策划</div>
        <div class="left-btn">
            <el-button type="success" @click="addBtn" v-if="!isSave">直播选品准备</el-button>
        </div>
        <div class="btn-content">
            <el-button type="primary" @click="saveScript" v-if="!isSave">保存脚本</el-button>
            <el-button type="primary" @click="promoteScript" v-else-if="isSave && showSEOScript">优化脚本</el-button>
        </div>
        <div class="bg-content"></div>
        <el-scrollbar class="left-content">
            <div class="left-box">
                <template v-for="(chooseItem, chooseIndex) in chooseGoodsList">
                    <div :key="`chooseGoodsList_${chooseIndex}`">
                        <div class="bg-title" v-if="chooseItem.goods_id">
                            <div class="left-control" @click="changeExpand(chooseIndex)">
                                <div :class="chooseItem.expand ? 'left-circle is-reverse' : 'left-circle'">
                                    <i class="el-icon-arrow-down"></i>
                                </div>
                                <div class="left-text">{{ chooseItem.expand ? "收起" : "展开" }}</div>
                            </div>
                            <img :src="require('../../assets/image/student/title-left.png')" alt="" />
                            <div class="text text-overflow">{{ chooseItem.goods_name }}</div>
                            <img :src="require('../../assets/image/student/title-right.png')" alt="" />
                            <div class="del-content" v-if="!chooseItem.script_id">
                                <el-button type="danger" size="small" @click="delGood(chooseItem, chooseIndex)" v-if="!isSave">
                                    批量删除
                                </el-button>
                            </div>
                        </div>
                        <template v-if="chooseItem.goods_id">
                            <div
                                v-if="chooseItem.expand"
                                v-for="(goodsItem, goodsIndex) in chooseItem.list"
                                :key="`chooseGoods_${chooseIndex}_${goodsIndex}`"
                            >
                                <div class="big-title">
                                    {{ goodsItem.minutes }}'{{ goodsItem.second }}
                                    <span v-if="goodsIndex === 0">(商品推销)</span>
                                    <div class="title-tips" v-if="goodsItem.script_type == 2">(优惠券)</div>
                                    <div class="title-tips" v-if="goodsItem.script_type == 3">(商品问答)</div>
                                </div>
                                <div class="content-box">
                                    <div class="sale-point" v-show="goodsItem.script_type == 4 && !isSave">
                                        <strong>产品卖点：</strong>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="请填写商品卖点"
                                            v-model="goodsItem.script_content"
                                            :ref="`salePoint_${chooseIndex}_${goodsIndex}`"
                                            style="margin-top: 6px; width: 100%"
                                        ></el-input>
                                        <div style="text-align: right; margin-top: 10px">
                                            <!--                                            <el-button type="success" size="small"-->
                                            <!--                                                       @click="saveSellingPoint(goodsItem,chooseIndex,goodsIndex)">-->
                                            <!--                                                保存-->
                                            <!--                                            </el-button>-->
                                        </div>
                                    </div>
                                    <div class="content-title" v-if="goodsItem.script_type == 4 && isSave">产品卖点：</div>
                                    <div class="content" v-if="goodsItem.script_type == 4 && !isSave"></div>
                                    <div class="content" v-else v-html="goodsItem.script_content"></div>
                                </div>
                            </div>
                        </template>
                        <div class="common-content" v-else>
                            <div class="big-title">{{ chooseItem.minutes }}'{{ chooseItem.second }}{{ chooseIndex === 0? '(直播开场)' : ''}}{{ chooseIndex === chooseGoodsList.length - 1? '(直播收尾)' : ''}}</div>
                            <div class="content-box" :key="chooseIndex">
                                <div class="content" v-html="chooseItem.script_content"></div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </el-scrollbar>
        <el-dialog title="直播选品准备" :visible.sync="dialogVisible" width="600px" custom-class="goods-selected-dialog">
            <div class="addGood-box">
                <div class="good-dialog">
                    <div class="add-tips">已经选择{{ multipleSelection.length }}件商品，还可以选择{{ leftNum }}件商品</div>
                    <div class="dialog-content">
                        <el-table
                            :data="goodsList"
                            style="width: 100%"
                            height="387px"
                            :header-cell-style="{
                                fontWeight: 'normal',
                                height: '40px',
                                color: '#5C5C6F',
                                fontSize: '14px',
                                background: '#F0F3FC',
                            }"
                            ref="multipleTable"
                            @selection-change="handleSelectionChange"
                            row-key="id"
                        >
                            <el-table-column
                                type="selection"
                                width="60"
                                :reserve-selection="true"
                                :selectable="selectDisable"
                            ></el-table-column>
                            <el-table-column label="商品信息">
                                <div class="good-info" slot-scope="scope">
                                    <div class="good-img">
                                        <img :src="scope.row.getGoodsInfo.goods_master_img" alt="" />
                                    </div>
                                    <div class="good-right" :title="scope.row.getGoodsInfo.goods_name">
                                        <div class="good-top">
                                            {{ scope.row.getGoodsInfo.goods_name }}
                                        </div>
                                        <div class="right-bottom">
                                            <div class="bottom-item">
                                                <span class="item-title">日常价：</span>
                                                <span class="item-price old-price"
                                                    >￥{{ scope.row.getGoodsInfo.goods_market_price }}</span
                                                >
                                            </div>
                                            <div class="bottom-item">
                                                <span class="item-title">直播间价：</span>
                                                <span class="item-price red-price"
                                                    >￥{{ scope.row.getGoodsInfo.goods_discount_price }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="dialog-bottom">
                        <el-button @click="closeDialog" size="small">取消</el-button>
                        <el-button type="primary" @click="addToGood('save')" size="small">确定</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    live_script_info,
    live_script_goods,
    live_script_save_goods,
    live_script_save,
    live_script_intro_list,
    live_script_goods_time,
    getStudentSetScriptGoodsData,
    live_script_del
} from "@/utils/apis";
import draggable from "vuedraggable";
import axios from "axios";

export default {
    name: "LiveScript",
    components: {
        draggable,
    },
    data() {
        return {
          role: Number(localStorage.getItem('role')),
            // 弹窗、
            dialogVisible: false,
            goodsList: [],
            multipleSelection: [],
            chooseGoodsList: [],
            scriptGoodsLength: 0,
            allowAddNum: 0,
            lastTime: 0,
            isSave: false,
            goodsIntro: [],
            scriptId: localStorage.getItem("scriptId"),
            trainId: localStorage.getItem("train_id"),
        };
    },
    mounted() {
      if (this.role === 4) {
        let arr = [
          {
            "start_time": 0,
            "end_time": 15,
            "script_type": 1,
            "script_content": "哈喽，大家好，欢迎大家来到本直播间，今天是至本洗护专场的直播，需要的宝宝们可以做好准备"
          },
          {
            "start_time": 15,
            "end_time": 25,
            "script_type": 1,
            "script_content": "那我们就不多说什么题外话了，直接开始今天的直播吧"
          },
          {
            "start_time": 25,
            "end_time": 35,
            "script_type": 1,
            "script_content": "那今天直播就先来给大家一份福利，先上一个福袋，宝宝们可以先领取福袋"
          },
          {
            "start_time": 36,
            "end_time": 46,
            "script_type": 3,
            "script_content": "左上角有个福袋，宝宝们可以去领取一下"
          },
          {
            "start_time": 46,
            "end_time": 56,
            "script_type": 3,
            "script_content": "福袋是限量领取的只有88个，宝宝们，先到先得"
          },
          {
            "start_time": 628,
            "end_time": 638,
            "script_type": 3,
            "script_content": "现在我们来发个福利"
          },
          {
            "start_time": 638,
            "end_time": 648,
            "script_type": 3,
            "script_content": "我们有个福袋可以领取，仅限粉丝哦，只有100个，先到先得"
          },
          {
            "start_time": 648,
            "end_time": 660,
            "script_type": 1,
            "script_content": "今天的直播就结束了，谢谢宝宝们的关顾，明晚7点不见不散"
          }
        ]
        this.chooseGoodsList = arr.map((item) => {
          item.minutes = item.end_time >= 60 ? parseInt(item.end_time / 60) : 0;
          item.second = item.end_time - item.minutes * 60;
          return item;
        });
      } else {
        this.promiseAll();
      }
    },
    computed: {
        leftNum() {
            let num = this.scriptGoodsLength - this.multipleSelection.length;
            return num < 0 ? 0 : num;
        },
        showSEOScript() {
            return this.$route.meta.parentName && !this.$route.meta.parentName.includes("LiveMarketing");
        },
    },
    methods: {
        selectDisable(row, index) {
            let goodIdArr = this.chooseGoodsList.map((item) => item.goods_id).filter(item => item).map(item => item * 1);
            if (goodIdArr.includes(row.goods_id * 1)) {
                return false;
            }
            return true;
        },
        promiseAll() {
            let p1 = this.getScript();
            let p2 = this.getGoodsIntro();
            let p3 = this.getGoodsList();
            Promise.all([p1, p2, p3]).then(([script, intro, goods]) => {
                this.chooseGoodsList = script.data.list.map((item) => {
                    item.minutes = item.end_time >= 60 ? parseInt(item.end_time / 60) : 0;
                    item.second = item.end_time - item.minutes * 60;
                    return item;
                });
                this.scriptGoodsLength = script.data.total_number;
                this.allowAddNum = script.data.total_number;

                this.goodsIntro = intro.data;

                this.goodsList = goods.data.list;

                this.goodsIntro.forEach((item, index) => {
                    let obj = this.goodsList.find((goodsItem) => goodsItem.goods_id == item.goods_id);
                    if (obj) {
                        this.multipleSelection.push(obj);
                    }
                });
                if (this.multipleSelection.length > 0) {
                    this.addToGood();
                }
            });
        },
        // 添加商品
        addBtn() {
            // this.$refs.multipleTable.clearSelection();
            this.dialogVisible = true;
            this.$nextTick(() => {
                let goodIdArr = this.chooseGoodsList.map((item) => item.goods_id * 1);
                this.goodsList.forEach((item) => {
                    const state = goodIdArr.includes(item.goods_id);
                    this.$refs.multipleTable.toggleRowSelection(item, state);
                });
            });
        },
        // 选择表格
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getScript() {
            return new Promise((resolve, reject) => {
                let params = {
                    id: this.scriptId,
                    exam_id: this.trainId,
                };
                live_script_info(params)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 获取弹窗内商品列表
        getGoodsList() {
            return new Promise((resolve, reject) => {
                let script_id = this.scriptId;
                let param = {
                    script_id: script_id,
                };
                live_script_goods(param)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getGoodsIntro() {
            return new Promise((resolve, reject) => {
                let param = {
                    train_id: this.trainId,
                };
                live_script_intro_list(param)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 关闭弹窗
        closeDialog(res) {
            this.dialogVisible = false;
            // if (res) {
            //     let goodsArr = this.chooseGoodsList.filter((item) => item.goods_id);
            //     let isEmpty = 0;
            //     goodsArr.forEach((item, index) => {
            //         item.list.forEach((subItem) => {
            //             if (subItem.script_type == 4 && !subItem.script_content) {
            //                 isEmpty = 1;
            //             }
            //         });
            //     });
            //     this.isSave = isEmpty == 1 ? false : true;
            // }
        },
        // 保存商品
        async addToGood(type='get') {
            if (this.multipleSelection.length === 0) {
                return this.$message.warning("请选择商品");
            } else if (this.multipleSelection.length > this.scriptGoodsLength) {
                return this.$message.warning("请选择指定数量的商品");
            }
            if (this.allowAddNum == 0) {
                return this.$message.warning("选择的商品数量达到限制");
            }
            this.allowAddNum = this.scriptGoodsLength - this.multipleSelection.length;
            let goodIdArr = this.chooseGoodsList.map((item) => item.goods_id);
            let len = this.multipleSelection.length;
            if(type == 'get') {
                this.getGoodsScript();
                return void 0;
            }
            for (let i = 0; i < len; i++) {
                const flag = goodIdArr.includes(JSON.stringify(this.multipleSelection[i].goods_id));
                if((type == "del" && flag) || !flag) {
                    await this.saveGoodsScript(
                        this.multipleSelection[i].goods_id,
                        this.multipleSelection[i].getGoodsInfo.goods_name,
                        i,
                        len
                    );
                }
            }
        },
        async saveGoodsScript(id, goods_name, index, len) {
            let params = {
                sort: index + 1,
                goods_id: id,
                script_id: this.scriptId,
            };
            await live_script_goods_time(params).then((res) => {
                let arr = [];
                if (res.data.length > 0) {
                    arr = res.data;
                    arr.map((item) => {
                        item.minutes = item.end_time >= 60 ? parseInt(item.end_time / 60) : 0;
                        item.second = item.end_time - item.minutes * 60;
                        return item;
                    });
                    let obj = {
                        goods_id: arr[0].goods_id,
                        goods_name: goods_name,
                        expand: true,
                        sort: "",
                        list: arr,
                    };
                    const goodsIndex = this.chooseGoodsList.findIndex(item => item.goods_id == obj.goods_id);
                    goodsIndex != -1 ? this.chooseGoodsList.splice(goodsIndex, 1, obj) : this.chooseGoodsList.push(obj);
                }
                if (index == len - 1) {
                    this.sortList();
                    this.closeDialog(true);
                }
            });
        },
        async getGoodsScript() {
            const res = await getStudentSetScriptGoodsData();
            if(!res || res.code != 200) {
                return void 0;
            }
            const goodsList = Object.keys(res.data);
            goodsList.forEach((goodsId, index) => {
                const goodsScriptList = res.data[goodsId];
                let arr = [];
                if (goodsScriptList.length > 0) {
                    arr = goodsScriptList;
                    arr.map((item) => {
                        item.minutes = item.end_time >= 60 ? parseInt(item.end_time / 60) : 0;
                        item.second = item.end_time - item.minutes * 60;
                        return item;
                    });
                    let obj = {
                        goods_id: arr[0].goods_id,
                        goods_name: this.multipleSelection.find(item => item.goods_id == goodsId).getGoodsInfo.goods_name,
                        expand: true,
                        sort: "",
                        list: arr,
                    };
                    this.chooseGoodsList.push(obj);
                }
                if (index == goodsList.length - 1) {
                    this.sortList();
                    this.closeDialog(true);
                }
            })
        },
        sortList() {
            //取出商品最后的时间
            this.chooseGoodsList.forEach((item) => {
                if (item.goods_id) {
                    let len = item.list.length;
                    let obj = item.list[len - 1];
                    item.start_time = obj.start_time;
                }
            });
            this.chooseGoodsList.sort((a, b) => {
                return a.start_time - b.start_time;
            });
        },
        // 删除商品
        delGood(item, index) {
            let goodIdArr = this.multipleSelection.map((item) => item.goods_id);
            let delIndex = goodIdArr.indexOf(Number(item.goods_id));
            this.$confirm("删除后将无法恢复，确定要删除？", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(() => {
                    let param = {
                        goods_id: item.goods_id,
                    };
                    axios({
                        method: "post",
                        params: param,
                        url: "live_script/del",
                        headers: { Authorization: localStorage.getItem("studentToken") },
                    })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.chooseGoodsList.splice(index, 1);
                                this.multipleSelection.splice(delIndex, 1);
                                this.allowAddNum++;
                                this.$message.success("删除成功！");
                                // this.getGoodsScript();
                                location.reload();
                            } else if (res.data.code == 400) {
                                this.chooseGoodsList.splice(index, 1);
                                this.multipleSelection.splice(delIndex, 1);
                                this.allowAddNum++;
                                this.$message.success("删除成功！");
                                this.addToGood('del')
                            }
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                })
                .catch(() => {
                    this.$message.info("已取消删除");
                });
        },
        changeExpand(index) {
            this.chooseGoodsList[index].expand = !this.chooseGoodsList[index].expand;
        },
        // 保存商品卖点
        async saveSellingPoint(arr, len, index, sort, goods_id) {
            // if (arr.length > 0) {
                let goods_introduce = arr.map((item) => item.script_content);
                let params = {
                    exam_id: this.trainId,
                    script_id: this.scriptId,
                    goods_id: goods_id,
                    goods_introduce: goods_introduce,
                    sort: sort,
                };
                await live_script_save_goods(params).then((res) => {
                    if (index == len - 1) {
                        this.saveAllPoint();
                    }
                });
            // }
        },
        saveScript() {
            if (this.multipleSelection.length === 0) {
                return this.$message.warning("请添加商品");
            }
            let isErrNum = 0;
            if (this.multipleSelection.length < this.scriptGoodsLength) {
                return this.$message.warning(`请选择${this.scriptGoodsLength}款商品进行添加`);
            }
            this.chooseGoodsList.forEach((item, index) => {
                if (item.goods_id) {
                    item.list.forEach((subItem, subIndex) => {
                        if (subItem.script_type == 4) {
                            if (!subItem.script_content) {
                                isErrNum = 1;
                                this.$refs[`salePoint_${index}_${subIndex}`][0].$el.classList.add("isError");
                            } else {
                                this.$refs[`salePoint_${index}_${subIndex}`][0].$el.classList.remove("isError");
                            }
                        }
                    });
                }
            });
            if (isErrNum === 1) {
                return this.$message.warning("产品卖点为必填");
            }
            let goodsArr = this.chooseGoodsList.filter((item) => item.goods_id);
            goodsArr.forEach((item, index) => {
                item.sort = index;
                let contentArr = [];
                item.list.forEach((subItem) => {
                    if (subItem.script_type == 4) {
                        contentArr.push(subItem);
                    }
                });
                this.saveSellingPoint(contentArr, goodsArr.length, index, item.sort, item.goods_id);
            });
            this.isSave = true;
        },
        saveAllPoint() {
            let formData = new FormData();
            formData.append("exam_id", this.trainId);
            formData.append("script_id", this.scriptId);
            live_script_save(formData)
                .then((res) => {
                    this.$message.success(res.msg);
                    this.isSave = true;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        promoteScript() {
            this.isSave = !this.isSave;
        },
        onMove(evt) {},
        onEnd(evt) {},
    },
};
</script>

<style scoped lang="scss">
.left-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
}
::v-deep .el-dialog.goods-selected-dialog {
    .el-dialog__body {
        padding: 10px 20px;
    }
    .el-dialog__title {
        font-size: 16px;
        color: #060111;
        font-weight: bold;
        font-family: Source Han Sans CN;
        position: relative;
        padding-left: 13px;
        &::before {
            width: 4px;
            height: 12px;
            content: "";
            background-color: #2821fc;
            position: absolute;
            top: 4px;
            left: -9px;
        }
    }
}
.left-container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, #12b8c4, #2821fc);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
    border-radius: 17px 0px 0px 20px;
    position: relative;

    .btn-content {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }

    .left-title {
        line-height: 36px;
        border: 1px solid #fff;
        display: inline-flex;
        margin: 30px auto 10px;
        width: auto;
        font-size: 24px;
        padding: 0 25px;
        position: relative;
        font-weight: bold;
        color: #fff;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: -1px;
            width: 5px;
            height: 36px;
            border: 1px solid #fff;
            border-radius: 2px;
        }

        &:before {
            left: -7px;
        }

        &:after {
            right: -7px;
        }
    }

    .bg-content {
        position: absolute;
        top: 34px;
        left: 30px;
        width: 554px;
        height: 266px;
        opacity: 0.4;
        background-image: url("../../assets/image/student/bg.png");
    }

    ::v-deep .left-content {
        height: 1%;
        flex: 1;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .left-box {
        padding: 0 20px 20px;
    }
}

.big-title {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-top: 12px;
    display: flex;
    align-items: center;

    .title-tips {
        line-height: 24px;
        margin-left: 10px;
    }
}

.content-box {
    background: #f0f3fc;
    border-radius: 10px;
    margin-top: 6px;
    color: #342e43;
    padding: 10px;

    .content-title {
        font-weight: bold;
        color: #342e43;
        margin-bottom: 13px;
    }

    .content {
        /*margin-top: 20px;*/
        /*padding: 0 10px;*/
    }
}

.bg-title {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: relative;

    .text {
        text-align: center;
        color: #fff;
        padding: 0 37px;
        position: relative;
        font-weight: bold;
        line-height: 24px;
        background: url("../../assets/image/student/title-bg.png") repeat-x;
    }

    .del-content {
        position: absolute;
        right: 0;
        top: 0;
    }

    .left-control {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .left-circle {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #2821fc;
            display: flex;
            align-items: center;
            justify-content: center;

            &.is-reverse {
                i {
                    transform: rotateZ(0);
                    transition: transform 0.3s;
                }
            }

            i {
                color: #fff;
                font-size: 8px;
                transform: rotate(-90deg);
                transition: transform 0.3s;
            }
        }

        .left-text {
            margin-left: 10px;
            color: #fff;
        }
    }
}

.line {
    margin-bottom: 8px;

    .num {
        display: inline-block;
        min-width: 20px;
        height: 20px;
        background: #2821fc;
        border-radius: 11px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        margin-right: 5px;
        padding: 0 4px;
        box-sizing: border-box;
    }

    .detail {
        display: inline;
    }
}

.good-info {
    display: flex;
    align-items: center;

    .good-img {
        width: 62px;
        height: 62px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .good-right {
        margin-left: 10px;
        width: 1%;
        flex: 1;
        height: 62px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .good-top {
            color: #1b162a;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }

        .right-bottom {
            display: flex;
            font-size: 12px;

            .bottom-item + .bottom-item {
                margin-left: 27px;
            }

            .old-price {
                text-decoration: line-through;
            }

            .red-price {
                color: #ff3e6c;
            }
        }
    }
}

.addGood-box {
    .good-dialog {
        background: #fff;
        /*position: absolute;
        top: 10px;
        left: -10px;
        right: -10px;
        z-index: 9;*/
        border-radius: 4px;

        .dialog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            font-size: 16px;
            color: #060111;
            font-weight: 500;

            .text {
                position: relative;
                padding-left: 12px;

                &:before {
                    content: "";
                    width: 4px;
                    height: 12px;
                    background: #2821fc;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .iconfont {
                font-size: 20px;
                cursor: pointer;
                color: #1b162a;
            }
        }

        .add-tips {
            margin: 0 0 10px 0px;
            font-size: 12px;
            color: #1b162a;
        }

        .dialog-content {
            //padding: 0 20px;
        }

        .dialog-bottom {
            padding: 10px 20px;
            border-top: 1px solid #dddfeb;
            text-align: right;
        }
    }
}

.el-table {
    ::v-deep th:first-child > .cell {
        padding-left: 15px;
    }
}
</style>
